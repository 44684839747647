/************************************************
Breakpoints
************************************************/
/************************************************
Imports
************************************************/
/* For custom font imports folder structure follows same as if we were to import an image
@font-face {
  font-family: myFirstFont;
  src: url(sansation_light.otf); //or .ttf
}
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
font-face {
  font-family: "TTNorms-Bold";
  src: url("../fonts/TTNorms-Bold.otf");
}

font-face {
  font-family: "TTNorms-Medium";
  src: url("../fonts/TTNorms-Medium.otf");
}

font-face {
  font-family: "TTNorms-Regular";
  src: url("../fonts/TTNorms-Regular.otf");
}

/************************************************
Colors
************************************************/
* {
  box-sizing: border-box;
}

/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************************************************
ADA Page Tabbing
************************************************/
button:focus,
input:focus,
a:focus,
textarea:focus,
select:focus {
  outline: none !important;
  outline-style: none !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

/************************************************
ADA User Tabbing
************************************************/
body.user-is-tabbing *:focus {
  outline: 2px solid #7AACFE !important;
  /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color !important;
}

/************************************************
HTML
************************************************/
html {
  max-width: 100%;
  scroll-behavior: smooth;
  font-size: 25px;
  /************************************************
  Body
  ************************************************/
}
html.no-scroll {
  overflow: hidden;
}
@media (max-width: 1200px) {
  html {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 16px;
  }
}
html body {
  overflow-x: hidden;
  max-width: 100%;
  height: auto;
  background: #FAFAFA;
  /************************************************
  Header
  ************************************************/
  /************************************************
  General/Global Classes
  ************************************************/
  /************************************************
  Forms
  ************************************************/
  /************************************************
  Font Sizing
  ************************************************/
  /************************************************
  Main
  ************************************************/
  /************************************************
  Sections
  ************************************************/
  /************************************************
  Footer
  ************************************************/
}
html body.no-scroll {
  overflow: hidden;
}
html body header {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 90px;
  opacity: 1;
  transition: opacity ease-in-out 0.4s;
}
html body header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
html body header.transparent {
  background: transparent !important;
  transition: opacity ease-in-out 0.4s, top ease-in-out 0.2s, background-color ease-in-out 0.4s;
}
html body header.nav-up {
  top: -999px;
  opacity: 0;
  transition: opacity ease-in-out 0.4s, top ease-in-out 0.2s, background-color ease-in-out 0.4s;
}
html body header.nav-down {
  top: 0px;
  background-color: #05191C;
  transition: background-color ease-in-out 0.4s, top ease-in-out 0.4s, opacity ease-in-out 0.4s;
}
html body header.solid {
  background-color: #05191C !important;
}
html body header .logo {
  height: 100%;
}
html body header .logo a {
  height: 100%;
  display: flex;
  align-items: center;
}
html body header .logo img {
  position: relative;
  margin-bottom: 0px;
  width: 28vw;
  height: auto;
  max-width: 250px;
  max-height: 80%;
}
@media (max-width: 1200px) {
  html body header .logo img {
    width: 24vw;
  }
}
@media (max-width: 991px) {
  html body header .logo img {
    width: 130px;
  }
}
html body header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-inline-start: 0;
  gap: 30px;
}
@media (max-width: 1200px) {
  html body header ul {
    gap: 15px;
  }
}
@media (max-width: 991px) {
  html body header ul {
    display: none;
  }
}
html body header ul li {
  position: relative;
}
html body header ul li a {
  font-family: "TTNorms-Bold", sans-serif;
  color: #FAFAFA;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}
html body header ul li a:hover {
  color: #015F2A;
}
html body header ul li a.btn {
  margin-top: 0;
}
html body header ul li:last-child {
  margin-right: 0;
}
html body #modal-background {
  height: 0;
  width: 0;
  overflow: hidden;
  pointer-events: none;
}
html body #modal-background.active {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}
html body #modal.active {
  width: 70vw;
  height: 70vh;
  position: fixed;
  background: #FAFAFA;
  color: #000000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  box-shadow: 0 35px 85px -75px rgba(0, 0, 0, 0.5);
  text-align: left;
  line-height: 1.2;
}
@media (max-width: 768px) {
  html body #modal.active {
    width: 85vw;
  }
}
html body #modal #modal-content {
  padding: 50px 30px;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  html body #modal #modal-content {
    padding: 20px;
  }
}
html body #modal #modal-content::-webkit-scrollbar {
  width: 7px;
}
html body #modal #modal-content::-webkit-scrollbar-track:vertical {
  background: #05191C;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:vertical {
  background: #015F2A;
  border-radius: 50px;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:window-inactive:vertical {
  background: #015F2A;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:vertical {
  background: #015F2A;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:vertical:active {
  background: #015F2A;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:vertical:window-inactive {
  background: #015F2A;
}
html body #modal #modal-content::-webkit-scrollbar-thumb:vertical:hover {
  background: #015F2A;
}
html body #modal #close {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media (max-width: 768px) {
  html body #modal #close {
    right: 0;
  }
}
html body .mobile-menu {
  display: none;
}
@media (max-width: 991px) {
  html body .mobile-menu {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    cursor: pointer;
  }
  html body .mobile-menu.active {
    width: 50px;
    height: 50px;
  }
  html body .mobile-menu.active .menu-bar.one {
    transform: rotate(-45deg);
    position: absolute;
  }
  html body .mobile-menu.active .menu-bar.two {
    margin: 0;
    transform: rotate(45deg);
    position: absolute;
  }
  html body .mobile-menu.active .menu-bar.three {
    opacity: 0;
  }
  html body .mobile-menu .menu-bar {
    height: 3px;
    width: 45px;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }
  html body .mobile-menu .menu-bar.two {
    margin: 5px 0;
  }
  html body .mobile-menu .menu-bar.three {
    opacity: 1;
  }
}
html body #menu {
  width: 100%;
  height: 100vh;
  top: -100vh;
  max-height: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  transition: top ease-in-out 0.5s, max-height 0.5s ease-in-out;
  background: linear-gradient(180deg, rgb(5, 25, 28) 0%, rgb(0, 34, 21) 100%);
}
html body #menu.show {
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  max-height: 100vh;
  overflow: scroll;
}
html body #menu .logo {
  position: relative;
  height: 100px;
  width: 100%;
  max-width: 80%;
  margin: 75px auto 50px;
  display: flex;
}
html body #menu .logo img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
}
html body #menu ul {
  opacity: 1;
  transition: opacity ease 1.5s;
  height: 50vh;
  overflow: scroll;
  padding-inline-start: 0;
}
html body #menu ul li {
  margin-bottom: 20px;
  list-style-type: none;
  position: relative;
}
html body #menu ul li ul {
  margin-top: 0;
  padding-inline-start: 0;
  overflow: hidden;
  max-height: 0;
  color: white;
  transition: max-height ease-in-out 0.5s, margin-top ease-in-out 0.5s, padding-inline-start 0.5s ease-in-out;
}
html body #menu ul li ul li {
  margin-bottom: 20px;
}
html body #menu ul li a {
  font-size: 1.5em;
  color: white;
  text-decoration: none;
  font-family: "TTNorms-Regular", sans-serif;
  font-weight: 400;
}
html body #menu .connect {
  background: #002215;
  width: 100%;
  padding: 25px 0;
  position: absolute;
  left: 0;
  bottom: 0;
}
html body #menu .connect a {
  color: white;
  display: block;
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
  margin-bottom: 15px;
  font-family: "TTNorms-Bold", sans-serif;
}
html body #menu .connect a:last-child {
  margin-bottom: 0;
}
html body #menu .connect .btn {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  padding: 15px 70px;
}
html body #menu .close {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 38px;
}
html body .container {
  width: 1620px;
  max-width: 95%;
  margin: auto;
}
html body .container.small {
  max-width: 69%;
}
@media (max-width: 991px) {
  html body .container.small {
    max-width: 85%;
  }
}
html body .container.medium {
  max-width: 85%;
}
html body .btn {
  background: #FAFAFA;
  padding: 12px 20px;
  border-radius: 0px;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  color: #05191C;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
html body .btn:hover {
  background: #002215;
  color: #FAFAFA;
}
html body .hide {
  height: 0;
  width: 0;
  font-size: 0;
  opacity: 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}
html body form .row {
  width: 100%;
  display: flex;
  gap: 30px;
  position: relative;
}
html body form .row .form-field {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
html body form .row .form-field input:not([type=checkbox]),
html body form .row .form-field select {
  color: #FAFAFA;
  height: 60px;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 20px;
  padding-left: 0px;
  font-family: "TTNorms-Bold", sans-serif;
  box-shadow: none;
  border-bottom: 2px solid #FAFAFA;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  background: transparent;
  font-size: 1.2rem;
  width: 100%;
}
html body form .row .form-field input:not([type=checkbox])::-moz-placeholder, html body form .row .form-field select::-moz-placeholder {
  color: #444 !important;
  font-size: 1.2rem !important;
}
html body form .row .form-field input:not([type=checkbox])::placeholder, html body form .row .form-field input:not([type=checkbox]) option,
html body form .row .form-field select::placeholder,
html body form .row .form-field select option {
  color: #444 !important;
  font-size: 1.2rem !important;
}
html body form .row .form-field select::before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../media/select-down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 24px;
  border: none;
  outline: none;
  pointer-events: none;
}
html body form .row .form-field input[type=checkbox] {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
html body form .row .form-field input[type=checkbox]:checked {
  accent-color: #319327;
}
html body form .row .form-field textarea {
  height: 180px !important;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 20px;
  padding-left: 0px;
  font-family: "TTNorms-Bold", sans-serif;
  box-shadow: none;
  border-bottom: 2px solid #FAFAFA;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  background: transparent;
  font-size: 1rem;
  width: 100%;
  color: #FAFAFA;
}
html body form .row .form-field textarea::-moz-placeholder {
  color: #FAFAFA !important;
  font-size: 1rem !important;
  font-weight: 700;
}
html body form .row .form-field textarea::placeholder, html body form .row .form-field textarea option {
  color: #FAFAFA !important;
  font-size: 1rem !important;
  font-weight: 700;
}
html body form .row .form-field label {
  position: absolute;
  font-family: "TTNorms-Bold", sans-serif;
  top: 20px;
  font-weight: 700;
  font-size: 1rem;
  transition: all ease-in-out 0.3s;
  pointer-events: none;
}
html body form .row .form-field label.red {
  color: red;
}
html body form .row .form-field.active label {
  top: -10px;
  font-size: 0.8rem;
  opacity: 0.6;
}
html body form .row .form-field .subscribe-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  color: white;
}
html body form .row .form-field .subscribe-wrapper span {
  font-weight: bold;
}
html body form .row .form-field button, html body form .row .form-field .btn {
  margin-top: 35px;
  font-size: 1.2rem;
  border: none;
  position: relative;
  margin: 30px auto 0;
  padding: 12px 50px;
  display: inline-block;
}
@media (max-width: 576px) {
  html body form .row .form-field button, html body form .row .form-field .btn {
    padding: 12px 30px;
    width: 100%;
  }
}
html body h1 {
  font-weight: 700;
  font-family: "TTNorms-Bold", sans-serif;
  margin-bottom: 15px;
}
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  font-weight: 500;
  font-family: "TTNorms-Medium", sans-serif;
  margin-bottom: 15px;
}
html body p,
html body a,
html body span,
html body ul,
html body ol,
html body li {
  font-weight: 300;
  font-family: "TTNorms-Regular", sans-serif;
}
html body h1 a, html body h2 a, html body h3 a, html body h4 a, html body h5 a, html body h6 a {
  font-size: inherit;
}
html body h1 {
  font-size: 3.8rem;
}
@media (max-width: 768px) {
  html body h1 {
    font-size: 3rem;
  }
}
html body h2 {
  font-size: 3.4rem;
}
@media (max-width: 768px) {
  html body h2 {
    font-size: 2.6rem;
  }
}
html body h3 {
  font-size: 3rem;
}
@media (max-width: 768px) {
  html body h3 {
    font-size: 1.8rem;
  }
}
html body h4 {
  font-size: 2.6rem;
}
@media (max-width: 768px) {
  html body h4 {
    font-size: 1.6rem;
  }
}
html body h5 {
  font-size: 2.2rem;
}
@media (max-width: 768px) {
  html body h5 {
    font-size: 1.2rem;
  }
}
html body h6 {
  font-size: 1.8rem;
}
html body p,
html body ul,
html body li,
html body ol,
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  color: #000000;
}
html body p,
html body a,
html body ul,
html body ol,
html body li {
  font-size: 1rem;
}
html body p {
  line-height: 1.25;
  margin-bottom: 15px;
}
html body p strong {
  font-weight: bold;
}
html body a, html body .modal {
  color: #319327;
  text-decoration: none;
  transition: color 0.4s ease-in-out;
}
html body a:hover, html body .modal:hover {
  color: #015F2A;
}
html body section {
  position: relative;
  padding: 35px 0px;
}
html body section:first-of-type {
  padding-top: 70px;
}
html body section:last-of-type {
  padding-bottom: 70px;
}
html body #hero {
  position: relative;
}
html body #hero.small {
  height: 300px;
}
html body #hero.medium {
  height: 500px;
}
html body #hero.large {
  height: 775px;
}
@media (max-width: 768px) {
  html body #hero.large {
    height: 600px;
  }
}
@media (max-width: 576px) {
  html body #hero.large {
    height: 525px;
  }
}
html body #hero .background-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
}
html body #hero .background-wrapper .full-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(5, 25, 28, 0.5);
  z-index: 1;
}
html body #hero .background-wrapper .bottom-overlay {
  position: absolute;
  width: 100%;
  height: 54%;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);
}
html body #hero .background-wrapper video,
html body #hero .background-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
html body #hero .container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
html body #hero .container .text-wrapper {
  z-index: 9;
  position: relative;
  bottom: 25px;
  text-align: left;
}
html body #hero .container .text-wrapper h1, html body #hero .container .text-wrapper h2, html body #hero .container .text-wrapper h3, html body #hero .container .text-wrapper h4, html body #hero .container .text-wrapper h5, html body #hero .container .text-wrapper h6, html body #hero .container .text-wrapper p {
  color: #FAFAFA;
}
html body #hero .container .text-wrapper.small {
  width: 55%;
}
@media (max-width: 768px) {
  html body #hero .container .text-wrapper.small {
    width: 60%;
  }
}
@media (max-width: 576px) {
  html body #hero .container .text-wrapper.small {
    width: 75%;
  }
}
@media (max-width: 425px) {
  html body #hero .container .text-wrapper.small {
    width: 85%;
  }
}
html body .parallax {
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  margin: 35px 0;
  background: linear-gradient(180deg, #05191C 0%, #05191C 100%);
}
html body .parallax.small {
  height: 435px;
}
@media (max-width: 768px) {
  html body .parallax.small {
    height: 300px;
  }
}
html body .parallax.medium {
  height: 445px;
}
html body .parallax.large {
  height: 765px;
}
@media (max-width: 991px) {
  html body .parallax.large {
    height: 500px;
  }
}
html body .parallax.no-margin-bottom {
  margin-bottom: 0;
}
html body .parallax.no-margin-top {
  margin-top: 0;
}
html body .parallax .overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(5, 25, 28, 0.8) 0%, rgba(5, 25, 28, 0.8) 100%);
}
html body .parallax .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
html body .parallax .container {
  height: 100%;
  width: 825px;
  max-width: 95%;
  position: relative;
}
html body .parallax .container .text-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  z-index: 1;
}
html body .parallax .container .text-wrapper h2, html body .parallax .container .text-wrapper h3, html body .parallax .container .text-wrapper h4, html body .parallax .container .text-wrapper h5, html body .parallax .container .text-wrapper h6, html body .parallax .container .text-wrapper p, html body .parallax .container .text-wrapper li {
  color: white;
}
html body .parallax .container .text-wrapper .button-wrapper {
  display: flex;
  justify-content: center;
}
html body .single-column {
  position: relative;
}
html body .single-column .container p {
  text-align: left;
}
html body .single-column .container .image-wrapper.top-left {
  position: absolute;
  top: -190px;
  left: -130px;
  z-index: 1;
  width: 535px;
  height: 302px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 991px) {
  html body .single-column .container .image-wrapper.top-left {
    top: -170px;
  }
}
html body .single-column .container .image-wrapper.bottom-right {
  position: absolute;
  bottom: -120px;
  right: -142px;
  z-index: 1;
  height: 404px;
  width: 508px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 991px) {
  html body .single-column .container .image-wrapper.bottom-right {
    height: 225px;
  }
}
html body .single-column .container .image-wrapper img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
html body .single-column .container .text-wrapper.left {
  text-align: left;
}
html body .single-column .container .text-wrapper ol {
  font-style: italic;
}
html body .single-column.green {
  background: #05191C;
}
html body .single-column.green .container {
  margin-left: 10%;
  margin-right: auto;
}
@media (max-width: 991px) {
  html body .single-column.green .container {
    margin-left: auto;
  }
}
html body .single-column.green .text-wrapper {
  width: 990px;
  max-width: 74%;
}
@media (max-width: 991px) {
  html body .single-column.green .text-wrapper {
    max-width: 100%;
  }
}
html body .single-column.green h1, html body .single-column.green h2, html body .single-column.green h3, html body .single-column.green h4, html body .single-column.green h5, html body .single-column.green h6, html body .single-column.green p {
  color: #FAFAFA;
}
html body .single-column.extra-padding {
  padding: 245px 0px 150px 0;
}
@media (max-width: 991px) {
  html body .single-column.extra-padding {
    padding: 175px 0px 150px 0;
  }
}
@media (max-width: 768px) {
  html body .single-column.extra-padding {
    padding: 125px 0px 150px 0;
  }
}
html body .single-column.bottom-padding {
  padding-bottom: 90px;
}
html body .two-column {
  position: relative;
  z-index: 2;
}
html body .two-column.reverse .container {
  flex-flow: row-reverse;
}
@media (max-width: 991px) {
  html body .two-column.reverse .container {
    flex-flow: column;
  }
}
@media (max-width: 991px) {
  html body .two-column.reverse .container .text-wrapper {
    width: 100%;
    padding-top: 30px;
    min-height: unset;
  }
}
html body .two-column.extra-padding {
  padding-top: 150px;
}
html body .two-column.object-fit img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
  -o-object-position: top left !important;
     object-position: top left !important;
}
html body .two-column .container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
@media (max-width: 991px) {
  html body .two-column .container {
    flex-flow: column;
  }
}
html body .two-column .container .image-wrapper {
  width: 47%;
  height: auto;
  position: relative;
}
@media (max-width: 991px) {
  html body .two-column .container .image-wrapper {
    width: 100%;
    height: 350px;
  }
}
html body .two-column .container .image-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
  top: 0;
  left: 0;
}
html body .two-column .container .text-wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 47%;
  height: 100%;
  min-height: 350px;
}
@media (max-width: 991px) {
  html body .two-column .container .text-wrapper {
    width: 100%;
    padding-top: 30px;
    min-height: unset;
  }
}
html body .two-column .container .text-wrapper .button-wrapper {
  display: flex;
  justify-content: center;
}
html body .two-column .container .text-wrapper .button-wrapper .btn {
  background: #05191C;
  color: #FAFAFA;
}
html body .two-column .container .text-wrapper.extra-small {
  min-height: 390px;
}
@media (max-width: 991px) {
  html body .two-column .container .text-wrapper.extra-small {
    min-height: unset;
  }
}
html body .two-column .container .text-wrapper.small {
  min-height: 505px;
}
@media (max-width: 991px) {
  html body .two-column .container .text-wrapper.small {
    min-height: unset;
  }
}
html body .two-column .container .text-wrapper.medium {
  min-height: 600px;
}
@media (max-width: 991px) {
  html body .two-column .container .text-wrapper.medium {
    min-height: unset;
  }
}
html body .two-column .container .text-wrapper.large {
  min-height: 630px;
}
@media (max-width: 991px) {
  html body .two-column .container .text-wrapper.large {
    min-height: unset;
  }
}
html body .two-column .container .text-wrapper.center {
  text-align: center;
}
html body .two-column .container .text-wrapper.bold h1, html body .two-column .container .text-wrapper.bold h2, html body .two-column .container .text-wrapper.bold h3, html body .two-column .container .text-wrapper.bold h4, html body .two-column .container .text-wrapper.bold h5, html body .two-column .container .text-wrapper.bold h6, html body .two-column .container .text-wrapper.bold p {
  font-weight: 700;
}
html body .two-column.full-width .container {
  max-width: 100%;
  margin: 0;
  width: 100%;
}
html body .two-column.full-width .container .image-wrapper {
  width: 41%;
}
@media (max-width: 991px) {
  html body .two-column.full-width .container .image-wrapper {
    width: 100%;
    height: 325px;
  }
}
html body .two-column.full-width .container .image-wrapper img {
  -o-object-position: top;
     object-position: top;
}
html body .two-column.full-width .container .text-wrapper {
  width: 58%;
  padding-right: 3.125%;
}
@media (max-width: 991px) {
  html body .two-column.full-width .container .text-wrapper {
    width: 100%;
    padding: 0 2.5%;
  }
}
html body .two-column.wide-text .container {
  max-width: 100%;
  margin: 0;
  width: 100%;
}
html body .two-column.wide-text .container .image-wrapper {
  width: 20%;
}
@media (max-width: 991px) {
  html body .two-column.wide-text .container .image-wrapper {
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: -30px;
  }
}
html body .two-column.wide-text .container .image-wrapper img {
  -o-object-position: top;
     object-position: top;
}
@media (max-width: 991px) {
  html body .two-column.wide-text .container .image-wrapper img {
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: right;
       object-position: right;
  }
}
html body .two-column.wide-text .container .text-wrapper {
  width: 74%;
  padding-left: 3.125%;
}
html body .two-column.wide-text .container .text-wrapper p {
  font-style: italic;
  font-weight: 700;
  font-size: 0.72rem;
}
html body .two-column-table div {
  display: flex;
  justify-content: space-between;
}
html body .two-column-table div .column-1, html body .two-column-table div .column-2, html body .two-column-table div .column-3 {
  padding: 34px 0;
}
html body .two-column-table div .column-1.header, html body .two-column-table div .column-2.header, html body .two-column-table div .column-3.header {
  font-size: 1.6rem;
}
html body .two-column-table div .column-1, html body .two-column-table div .column-2 {
  width: 48%;
  text-align: left;
}
html body .two-column-table div .column-2 {
  display: flex;
  flex-flow: column;
}
html body .two-column-table div h1, html body .two-column-table div h2, html body .two-column-table div h3, html body .two-column-table div h4, html body .two-column-table div h5, html body .two-column-table div h6, html body .two-column-table div p {
  color: #FAFAFA;
  font-weight: 700;
}
html body .two-column-table div.black-green {
  background: #05191C;
}
html body .two-column-table div.dark-green {
  background: #002215;
}
html body .two-column-table div.medium-green {
  background: rgba(0, 34, 21, 0.9);
}
html body .contact .fade-background {
  height: 172%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);
  z-index: 1;
}
html body .contact .green-background {
  background: linear-gradient(180deg, rgba(5, 25, 28, 0.95) 0%, rgba(0, 34, 21, 0.95) 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
html body .contact .small-circle, html body .contact .large-circle {
  z-index: 0;
  position: absolute;
}
html body .contact .small-circle {
  height: 300px;
  width: 300px;
  background: #319327;
  box-shadow: 0px 0px 150px 150px #319327;
  border-radius: 500px;
}
html body .contact .large-circle {
  height: 980px;
  width: 980px;
  background: #319327;
  opacity: 0.4;
  box-shadow: 0px 0px 150px 150px #319327;
  border-radius: 500px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
html body .contact .top-right {
  top: 0;
  right: 0;
}
html body .contact .bottom-left {
  bottom: 0;
  left: 0;
}
html body .contact .container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
}
@media (max-width: 991px) {
  html body .contact .container {
    flex-flow: column;
    gap: 50px;
  }
}
html body .contact .container .left, html body .contact .container .right {
  display: flex;
  flex-flow: column;
}
html body .contact .container .left label, html body .contact .container .left h1, html body .contact .container .left h2, html body .contact .container .left h3, html body .contact .container .left h4, html body .contact .container .left h5, html body .contact .container .left h6, html body .contact .container .left p, html body .contact .container .right label, html body .contact .container .right h1, html body .contact .container .right h2, html body .contact .container .right h3, html body .contact .container .right h4, html body .contact .container .right h5, html body .contact .container .right h6, html body .contact .container .right p {
  color: #FAFAFA;
  text-align: left;
}
html body .contact .container .left {
  width: 43%;
}
@media (max-width: 991px) {
  html body .contact .container .left {
    width: 100%;
  }
}
html body .contact .container .left .contact-info {
  margin-top: 25px;
  background: linear-gradient(159.55deg, #FFFFFF 0%, #015F2A 156.71%);
  box-shadow: 0px 10px 55px -25px rgba(255, 255, 255, 0.5);
  border-radius: 45px;
  padding: 50px 30px;
}
@media (max-width: 1600px) {
  html body .contact .container .left .contact-info {
    padding: 50px 20px;
  }
}
html body .contact .container .left .contact-info h1, html body .contact .container .left .contact-info h2, html body .contact .container .left .contact-info h3, html body .contact .container .left .contact-info h4, html body .contact .container .left .contact-info h5, html body .contact .container .left .contact-info h6, html body .contact .container .left .contact-info p, html body .contact .container .left .contact-info a {
  color: #05191C;
}
html body .contact .container .left .contact-info h4 {
  text-align: center;
  margin-bottom: 40px;
}
html body .contact .container .left .contact-info .content-wrapper {
  display: flex;
  gap: 35px;
  align-items: center;
}
@media (max-width: 1600px) {
  html body .contact .container .left .contact-info .content-wrapper {
    gap: 20px;
  }
}
@media (max-width: 1200px) {
  html body .contact .container .left .contact-info .content-wrapper {
    gap: 10px;
  }
}
html body .contact .container .left .contact-info .content-wrapper h6 {
  margin-bottom: 0;
  font-size: 1.4rem;
}
@media (max-width: 1600px) {
  html body .contact .container .left .contact-info .content-wrapper h6 {
    font-size: 1.2rem;
  }
}
html body .contact .container .left .contact-info .content-wrapper a {
  font-weight: inherit;
}
html body .contact .container .left .contact-info .address {
  margin-bottom: 60px;
}
html body .contact .container .right {
  width: 52%;
}
@media (max-width: 991px) {
  html body .contact .container .right {
    width: 100%;
  }
}
html body .contact.green-background {
  padding-top: 220px;
  padding-bottom: 90px;
  overflow: hidden;
}
@media (max-width: 991px) {
  html body .contact.green-background {
    padding-top: 120px;
  }
}
html body .contact-product .container label, html body .contact-product .container h1, html body .contact-product .container h2, html body .contact-product .container h3, html body .contact-product .container h4, html body .contact-product .container h5, html body .contact-product .container h6, html body .contact-product .container p, html body .contact-product .container span {
  color: #05191C;
}
html body .contact-product .container label.modal, html body .contact-product .container h1.modal, html body .contact-product .container h2.modal, html body .contact-product .container h3.modal, html body .contact-product .container h4.modal, html body .contact-product .container h5.modal, html body .contact-product .container h6.modal, html body .contact-product .container p.modal, html body .contact-product .container span.modal {
  color: #319327;
  cursor: pointer;
}
html body .contact-product .container label.modal:hover, html body .contact-product .container h1.modal:hover, html body .contact-product .container h2.modal:hover, html body .contact-product .container h3.modal:hover, html body .contact-product .container h4.modal:hover, html body .contact-product .container h5.modal:hover, html body .contact-product .container h6.modal:hover, html body .contact-product .container p.modal:hover, html body .contact-product .container span.modal:hover {
  opacity: 0.8;
}
html body .contact-product .container .top-content {
  text-align: left;
}
html body .contact-product .container #product-contact {
  width: 100%;
  text-align: left;
}
html body .contact-product .container #product-contact button, html body .contact-product .container #product-contact .btn {
  background: #002215;
  color: #FAFAFA;
  transition: background-color 0.3s ease-in-out;
}
html body .contact-product .container #product-contact button:hover, html body .contact-product .container #product-contact .btn:hover {
  background: #015F2A;
  color: #FAFAFA;
}
html body .contact-product .container #product-contact input,
html body .contact-product .container #product-contact textarea,
html body .contact-product .container #product-contact select,
html body .contact-product .container #product-contact .css-b62m3t-container,
html body .contact-product .container #product-contact .css-13cymwt-control {
  border-bottom: 2px solid #05191C;
  color: #05191C;
}
html body .contact-product .container #product-contact .css-1jqq78o-placeholder,
html body .contact-product .container #product-contact .css-1dimb5e-singleValue {
  color: #05191C;
  font-family: "TTNorms-Bold", sans-serif;
}
html body .contact-product .container #product-contact label {
  width: 100%;
}
html body .contact-product .container #product-contact .name, html body .contact-product .container #product-contact .physician, html body .contact-product .container #product-contact .physician-email {
  width: 48%;
}
html body .contact-product .container #product-contact .city, html body .contact-product .container #product-contact .state, html body .contact-product .container #product-contact .zip-code {
  width: 32%;
}
html body .contact-product .container #product-contact textarea::-moz-placeholder {
  color: #05191C !important;
  font-size: 1.2rem !important;
}
html body .contact-product .container #product-contact textarea::placeholder, html body .contact-product .container #product-contact textarea option {
  color: #05191C !important;
  font-size: 1.2rem !important;
}
html body .contact-product .container #product-contact .css-1u9des2-indicatorSeparator {
  display: none;
  color: #05191C;
}
html body .contact-product .container #product-contact .css-1jqq78o-placeholder {
  color: #05191C;
  font-family: "TTNorms-Bold", sans-serif;
  font-weight: 700;
}
html body .services h3 {
  margin-bottom: 45px;
}
html body .services .grid {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 45px;
  justify-items: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}
@media (max-width: 991px) {
  html body .services .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  html body .services .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
html body .services .grid .grid-card {
  background: linear-gradient(154.18deg, #05191C 0%, #015F2A 100%);
  box-shadow: 0px 20px 40px -20px rgba(1, 95, 42, 0.5);
  border-radius: 45px;
  padding: 0 30px;
  height: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
}
html body .services .grid .grid-card p {
  color: #FAFAFA;
  font-weight: 700;
  margin-bottom: 0;
}
html body .faq {
  padding-top: 140px !important;
  padding-bottom: 70px;
}
html body .faq h2 {
  text-align: left;
  margin-bottom: 40px;
}
html body .faq .faq-wrapper {
  text-align: left;
  cursor: pointer;
}
html body .faq .faq-wrapper h6 {
  color: #015F2A;
  font-size: 1.2rem;
}
html body .faq .faq-wrapper hr {
  background: #D9D9D9;
  border: none;
  height: 2px;
  margin: 0;
}
html body .faq .faq-wrapper .question-wrapper {
  padding: 42px 0 25px 0;
}
html body .faq .faq-wrapper .question-wrapper .icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
html body .faq .faq-wrapper .question-wrapper .icon-wrapper h5 {
  color: #015F2A;
  font-size: 1.8rem;
  margin-block-start: 0;
  margin-block-end: 0;
}
html body .faq .faq-wrapper .question-wrapper:first-child {
  padding: 0 0 25px 0;
}
html body .faq .faq-wrapper .question-wrapper:last-child {
  padding: 42px 0 0 0;
}
html body .process {
  padding-top: 120px !important;
  background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);
}
html body .process h3 {
  margin-bottom: 55px;
}
html body .process .step {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body .process .step h6 {
  font-size: 1.4rem;
  color: white;
}
@media (max-width: 576px) {
  html body .process .step h6 {
    font-size: 1rem;
  }
}
html body .process .process-slider {
  display: flex;
  gap: 65px;
  overflow-x: scroll;
  height: 680px;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (max-width: 991px) {
  html body .process .process-slider {
    height: auto;
    justify-content: stretch;
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  html body .process .process-slider {
    gap: 15px;
  }
}
html body .process .process-slider .process-slider-card {
  background: linear-gradient(154.18deg, #05191C 0%, #015F2A 100%);
  box-shadow: 0px 20px 40px -20px rgba(1, 95, 42, 0.5);
  border-radius: 45px;
  padding: 30px 20px 10px;
  position: relative;
  height: 560px;
  width: 650px;
  display: flex;
  flex-flow: column;
  gap: 45px;
  flex: 0 0 auto;
}
@media (max-width: 991px) {
  html body .process .process-slider .process-slider-card {
    height: auto;
    max-width: 70%;
  }
}
@media (max-width: 576px) {
  html body .process .process-slider .process-slider-card {
    max-width: 90%;
    gap: 15px;
  }
}
html body .process .process-slider .process-slider-card h3, html body .process .process-slider .process-slider-card h6, html body .process .process-slider .process-slider-card p {
  color: #FAFAFA;
  text-align: left;
}
html body .process .process-slider .process-slider-card h3 {
  margin-bottom: 0;
}
html body .process .process-slider .process-slider-card .top {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
html body .process .process-slider .process-slider-card .top .image-wrapper {
  position: relative;
}
html body .process .process-slider .process-slider-card .top .image-wrapper img {
  width: 125px;
  height: 125px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 991px) {
  html body .process .process-slider .process-slider-card .top .image-wrapper img {
    height: 75px;
    width: 75px;
  }
}
html body .process .process-slider .process-slider-card .top .card-number {
  position: relative;
}
html body .process .process-slider .process-slider-card .bottom .card-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
html body .process .process-slider::-webkit-scrollbar {
  height: 30pxpx;
  width: 30pxpx;
  background: #05191C;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
}
html body .process .process-slider::-webkit-scrollbar-track {
  background: #015F2A;
}
html body .process .process-slider::-webkit-scrollbar-thumb {
  background: #05191C;
  border-radius: 0;
}
html body .process .process-slider::-webkit-scrollbar-thumb:hover {
  background: #319327;
}
html body .process .process-slider::-webkit-scrollbar-thumb:horizontal {
  background-color: #05191C;
  border-radius: 0;
  width: 100px;
}
html body footer {
  background: #05191C;
  padding: 43px 0;
}
html body footer .logo img {
  height: 75px;
  width: 75px;
}
html body footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  html body footer .container {
    flex-flow: column;
    gap: 30px;
  }
}
html body footer p, html body footer a {
  font-weight: 700;
  color: #FAFAFA;
  margin-bottom: 0;
}
html body footer br {
  display: none;
}
@media (max-width: 1200px) {
  html body footer br {
    display: block;
  }
}/*# sourceMappingURL=style.css.map */
/************************************************
Breakpoints
************************************************/
@mixin breakpoint($class) {
	@if $class==xxs {
		@media (max-width: 400px) {
			@content;
		}
	}

	@else if $class==xs {
		@media (max-width: 425px) {
			@content;
		}
	}

	@else if $class==sm {
		@media (max-width: 576px) {
			@content;
		}
	}

	@else if $class==md {
		@media (max-width: 768px) {
			@content;
		}
	}

	@else if $class==lg {
		@media (max-width: 991px) {
			@content;
		}
	}

	@else if $class==xl {
		@media (max-width: 1200px) {
			@content;
		}
	}

	@else if $class==mair {
		@media (max-width: 1350px) {
			@content;
		}
	}

	@else if $class==xxl {
		@media (max-width: 1600px) {
			@content;
		}
	}

	@else {
		@warn "Breakpoint mixin supports: sm, md, lg, xxl";
	}
}

/************************************************
Imports
************************************************/
/* For custom font imports folder structure follows same as if we were to import an image
@font-face {
  font-family: myFirstFont;
  src: url(sansation_light.otf); //or .ttf
}
*/
//import font url polomar/src/assets/fonts/TTNorms-Bold.otf
font-face {
	font-family: 'TTNorms-Bold';
	src: url('../fonts/TTNorms-Bold.otf');
}
//import font url polomar/src/assets/fonts/TTNorms-Medium.otf
font-face {
	font-family: 'TTNorms-Medium';
	src: url('../fonts/TTNorms-Medium.otf');
}
//import font url polomar/src/assets/fonts/TTNorms-Regular.otf
font-face {
	font-family: 'TTNorms-Regular';
	src: url('../fonts/TTNorms-Regular.otf');
}


@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$normsBold: 'TTNorms-Bold', sans-serif;
$normsReg: 'TTNorms-Regular', sans-serif;
$normsMed: 'TTNorms-Medium', sans-serif;

%shadow {
	-webkit-box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.25);
}

/************************************************
Colors
************************************************/
$dark-blue:#0b0a26;
$light-blue:#1b7ea6;
$footer-color: #242424;

$rich-green-black: #05191C;
$dark-green: #002215;
$green: #015F2A;
$highlight-green: #319327;
$luxury-white: #FAFAFA;



$black: #000000; //For general font color

* {
	box-sizing: border-box;
}

/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/************************************************
ADA Page Tabbing
************************************************/
button:focus,
input:focus,
a:focus,
textarea:focus,
select:focus {
	outline: none !important;
	outline-style: none !important;
	border-color: inherit !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

/************************************************
ADA User Tabbing
************************************************/
body.user-is-tabbing *:focus {
	outline: 2px solid #7AACFE !important;
	/* for non-webkit browsers */
	outline: 5px auto -webkit-focus-ring-color !important;
}

/************************************************
HTML
************************************************/
html {
	max-width: 100%;
	scroll-behavior: smooth;

	&.no-scroll {
		overflow: hidden;
	}

	font-size: 25px; //default

	@include breakpoint(xl) {
		font-size: 22px;
	}

	@include breakpoint(lg) {
		font-size: 20px;
	}

	@include breakpoint(md) {
		font-size: 18px;
	}

	@include breakpoint(sm) {
		font-size: 16px;
	}

	/************************************************
	Body
	************************************************/
	body {
		overflow-x: hidden;
		max-width: 100%;
		height: auto;
        background: $luxury-white;

		&.no-scroll {
			overflow: hidden;
		}

		/************************************************
		Header
		************************************************/
		header {
			background: transparent;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			width: 100%;
			height: 90px;
			opacity: 1;
			transition: opacity ease-in-out .4s;

			.container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 100%;
			}

			&.transparent {
				background: transparent !important;
				transition: opacity ease-in-out .4s, top ease-in-out .2s, background-color ease-in-out .4s;
			}

			&.nav-up {
				top: -999px;
				opacity: 0;
				transition: opacity ease-in-out .4s, top ease-in-out .2s, background-color ease-in-out .4s;
			}

			&.nav-down {
				top: 0px;
				background-color: $rich-green-black;
				transition: background-color ease-in-out .4s, top ease-in-out .4s, opacity ease-in-out .4s;
			}

			&.solid {
				background-color: $rich-green-black !important;
			}

			.logo {
				height: 100%;

				a {
					height: 100%;
					display: flex;
					align-items: center;
				}

				img {
					position: relative;
					margin-bottom: 0px;
					width: 28vw;
					height: auto;
					max-width: 250px;
					max-height: 80%;

					@include breakpoint(xl) {
						width: 24vw;
					}
					@include breakpoint(lg) {
						width: 130px;
					}
				}
			}

			ul {
				display: flex;
				justify-content: space-between;
				align-items: center;
				list-style: none;
				padding-inline-start: 0;
				gap: 30px;

				@include breakpoint(xl) {
					gap: 15px;
				}

				@include breakpoint(lg) {
					display: none;
				}

				li {
					position: relative;

					a {
                        font-family: $normsBold;
						color: $luxury-white;
						text-decoration: none;
						cursor: pointer;
						font-size: 1.2rem;
                        font-weight: 700;

						&:hover {
							color: $green;
						}

                        &.btn {
                            margin-top: 0;
                        }
					}

                    &:last-child {
                        margin-right: 0;
                    }
				}
			}
		}

		
		//modal
		#modal-background {
			height: 0;
			width: 0;
			overflow: hidden;
			pointer-events: none;
			&.active {
				width: 100vw;
				height: 100vh;
				position: fixed;
				left: 0;
				top: 0;
				background: rgba(0,0,0,0.5);
				z-index: 9998;
			}
		}
		#modal {
			&.active {
				width: 70vw;
				height: 70vh;
				position: fixed;
				background: $luxury-white;
				color: $black;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				z-index: 9999;
				box-shadow: 0 35px 85px -75px rgba(0,0,0,0.5);
				text-align: left;
				line-height: 1.2;
				@include breakpoint(md) {
					width: 85vw;
				}
			}

			#modal-content {
				padding: 50px 30px;
				overflow-y: scroll;
				width: 100%;
				height: 100%;

				@include breakpoint(md) {
					padding: 20px;
				}

				//scrollbar always visible
				&::-webkit-scrollbar {
					width: 7px;
				}
				&::-webkit-scrollbar-track:vertical {
					background: $rich-green-black;
				}
				&::-webkit-scrollbar-thumb:vertical {
					background: $green;
					border-radius: 50px;
				}
				&::-webkit-scrollbar-thumb:window-inactive:vertical {
					background: $green;
				}
				&::-webkit-scrollbar-thumb:vertical {
					background: $green;
				}
				&::-webkit-scrollbar-thumb:vertical:active {
					background: $green;
				}
				&::-webkit-scrollbar-thumb:vertical:window-inactive {
					background: $green;
				}
				&::-webkit-scrollbar-thumb:vertical:hover {
					background: $green;
				}
			}

			#close {
				position: absolute;
				top: -50px;
				right: -50px;
				width: 30px;
				height: 30px;
				cursor: pointer;
				@include breakpoint(md) {
					right: 0;
				}
			}
		}

		//Custom Mobile Menu Button
		.mobile-menu {
			display: none;

			@include breakpoint(lg) {
				display: block;
				position: relative;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				cursor: pointer;

				&.active {
					width: 50px;
					height: 50px;

					.menu-bar {
						&.one {
							transform: rotate(-45deg);
							position: absolute;
						}

						&.two {
							margin: 0;
							transform: rotate(45deg);
							position: absolute;
						}

						&.three {
							opacity: 0;
						}
					}

				}
				.menu-bar {
					height: 3px;
					width: 45px;
					background-color: white;
					transition: all .3s ease-in-out;

					&.two {
						margin: 5px 0;
					}

					&.three {
						opacity: 1;
					}
				}
			}

		}

		#menu {
			width: 100%;
			height: 100vh;
			top: -100vh;
			max-height: 0;
			left: 0;
			z-index: 9999;
			position: fixed;
			transition: top ease-in-out .5s, max-height .5s ease-in-out;
			background: linear-gradient(180deg, rgba(5, 25, 28, 1) 0%, rgba(0, 34, 21, 1) 100%);

			&.show {
				width: 100%;
				height: 100vh;
				top: 0;
				position: fixed;
				max-height: 100vh;
				overflow: scroll;
			}

			.logo {
				position: relative;
				height: 100px;
				width: 100%;
				max-width: 80%;
				margin: 75px auto 50px;
				display: flex;

				img {
					height: 100%;
					width: 100%;
					object-fit: contain;
					position: relative;
				}
			}

			ul {
				opacity: 1;
				transition: opacity ease 1.5s;
				height: 50vh;
				overflow: scroll;
				padding-inline-start: 0;

				li {
					margin-bottom: 20px;
					list-style-type: none;
					position: relative;

					ul {
						margin-top: 0;
						padding-inline-start: 0;
						overflow: hidden;
						max-height: 0;
						color: white;
						transition: max-height ease-in-out .5s, margin-top ease-in-out .5s, padding-inline-start 0.5s ease-in-out;

						li {
							margin-bottom: 20px;
						}
					}

					a {
						font-size: 1.5em;
						color: white;
						text-decoration: none;
						font-family: $normsReg;
						
						font-weight: 400;
					}
				}
			}

			.connect {
				background: $dark-green;
				width: 100%;
				padding: 25px 0;
				position: absolute;
				left: 0;
				bottom: 0;

				a {
					color: white;
					display: block;
					text-align: center;
					font-size: 1.4rem;
					text-decoration: none;
					margin-bottom: 15px;
					font-family: $normsBold;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.btn {
					width: 90%;
					margin: auto;
					margin-top: 20px;
					padding: 15px 70px;
				}
			}

			.close {
				width: 100%;
				position: relative;
				display: flex;
				justify-content: flex-end;
				top: 38px;
			}
		}

		/************************************************
		General/Global Classes
		************************************************/
		.container {
			width: 1620px;
			max-width: 95%;
			margin: auto;

			&.small {
				max-width: 69%;
				@include breakpoint(lg) {
					max-width: 85%;
				}
			}

			&.medium {
				max-width: 85%;
			}
		}

		.btn {
			background: $luxury-white;
			padding: 12px 20px;
			border-radius: 0px;
			position: relative;
			display: inline-block;
			margin-top: 10px;
			color: $rich-green-black;
			text-decoration: none;
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
			text-align: center;
			cursor: pointer;
            font-weight: 700;

			&:hover {
				background: $dark-green;
                color: $luxury-white;
			}
		}
		.hide {
			height: 0;
			width: 0;
			font-size: 0;
			opacity: 0;
			z-index: 0;
			overflow: hidden;
			pointer-events: none;
		}

		/************************************************
		Forms
		************************************************/
		form {
			// .css-b62m3t-container {
			// 	color: $rich-green-black;
			// }

			// .css-1u9des2-indicatorSeparator {
			// 	display: none;
			// 	color: $luxury-white;
			// }

			// .css-1jqq78o-placeholder,
			// .css-1dimb5e-singleValue {
			// 	color: $luxury-white;
			// 	font-family: $normsBold;
			// }

			// .css-1xc3v61-indicatorContainer {
			// 	svg {
			// 		height: 64px;
			// 		width: 64px;
			// 	}
			// }

			// svg,
			// .css-1jqq78o-placeholder,
			// .css-1dimb5e-singleValue {
			// 	top: 10px;
			// 	position: absolute;
			// }

			// svg {
			// 	right: 0;
			// 	top: -10px;
			// }
			.row {
				width: 100%;
				display: flex;
				gap: 30px;
				position: relative;
				.form-field {
					width: 100%;
					position: relative;
					margin-bottom: 10px;
					input:not([type=checkbox]),
					select {
						color: $luxury-white;
						height: 60px;
						border-radius: 0;
						box-shadow: none;
						margin-bottom: 20px;
						padding-left: 0px;
						font-family: $normsBold;
						-webkit-box-shadow: none;
						box-shadow: none;
						border-bottom: 2px solid $luxury-white;
						border-right: 0;
						border-left: 0;
						border-top: 0;
						background: transparent;
						font-size: 1.2rem;
						width: 100%;
		
						&::placeholder, option {
							color: #444 !important;
							font-size: 1.2rem !important;
						}
					}
					
					select {
						&::before {
							content: "";
							width: 24px;
							height: 24px;
							background-image: url('../media/select-down-arrow.svg');
							background-repeat: no-repeat;
							background-position: right center;
							background-size: 24px;
							border: none;
							outline: none;
							pointer-events: none;
						}
					}

					input[type=checkbox] {
						height: 30px;
						width: 30px;
						cursor: pointer;
		
						&:checked {
							accent-color: $highlight-green;
						}
					}

					textarea {
						height: 180px !important;
						border-radius: 0;
						box-shadow: none;
						margin-bottom: 20px;
						padding-left: 0px;
						font-family: $normsBold ;
						-webkit-box-shadow: none;
						box-shadow: none;
						border-bottom: 2px solid $luxury-white;
						border-right: 0;
						border-left: 0;
						border-top: 0;
						background: transparent;
						font-size: 1rem;
						width: 100%;
						color: $luxury-white;
		
						&::placeholder, option {
							color: $luxury-white !important;
							font-size: 1rem !important;
							font-weight: 700;
						}
					}

					label {
						position: absolute;
						font-family: $normsBold;
						top: 20px;
						font-weight: 700;
						font-size: 1rem;
						transition: all ease-in-out .3s;
						pointer-events: none;
						&.red {
							color: red;
						}
					}
					&.active {
						label {
							top: -10px;
							font-size: .8rem;
							opacity: .6;
						}
					}
					.subscribe-wrapper {
						display: flex;
						gap: 10px;
						align-items: center;
						color: white;
						span {
							font-weight: bold;
						}
					}
					button, .btn {
						margin-top: 35px;
						font-size: 1.2rem;
						border: none;
						position: relative;
						margin: 30px auto 0;
						padding: 12px 50px;
						display: inline-block;
						@include breakpoint(sm) {
							padding: 12px 30px;
							width: 100%;
						}
					}
				}
			}
		}

		/************************************************
		Font Sizing
		************************************************/
		h1 {
			font-weight: 700;
			font-family: $normsBold;
			margin-bottom: 15px;
		}
		
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 500;
			font-family: $normsMed;
			margin-bottom: 15px;
		}

		p,
		a,
		span,
		ul,
		ol,
		li {
			font-weight: 300;
			font-family: $normsReg;
			
		}

		h1, h2, h3, h4, h5, h6 {
			a {
				font-size: inherit;
			}
		}

		h1 {
			font-size: 3.8rem;

			@include breakpoint(md) {
				font-size: 3rem;
			}
		}

		h2 {
			font-size: 3.4rem;

			@include breakpoint(md) {
				font-size: 2.6rem;
			}
		}

		h3 {
			font-size: 3rem;

			@include breakpoint(md) {
				font-size: 1.8rem;
			}
		}

		h4 {
			font-size: 2.6rem;

			@include breakpoint(md) {
				font-size: 1.6rem;
			}
		}

		h5 {
			font-size: 2.2rem;

			@include breakpoint(md) {
				font-size: 1.2rem;
			}
		}

		h6 {
			font-size: 1.8rem;
		}

		p,
		ul,
		li,
		ol,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $black;
		}

		p,
		a,
		ul,
		ol,
		li {
			font-size: 1rem;
		}

		p {
			line-height: 1.25;
			margin-bottom: 15px;

			strong {
				font-weight: bold;
			}
		}
		a, .modal {
			color: $highlight-green;
			text-decoration: none;
			transition: color .4s ease-in-out;

			&:hover {
				color: $green;
			}

			&:visited {

			}
		}


		/************************************************
		Main
		************************************************/
		// main {
		// 	max-width: 100%;
		// 	overflow: hidden;

            /************************************************
			Sections
			************************************************/
			section {
				position: relative;
				padding: 35px 0px;

				&:first-of-type {
					padding-top: 70px;
				}

				&:last-of-type {
					padding-bottom: 70px;
				}
			}

            #hero {
				position: relative;

				&.small {
					height: 300px;
				}

				&.medium {
					height: 500px;
				}

				&.large {
					height: 775px;

					@include breakpoint(md) {
						height: 600px;
					}

					@include breakpoint(sm) {
						height: 525px;
					}
				}

				.background-wrapper {
					position: absolute;
					height: 100%;
					width: 100%;
					z-index: 0;

					.full-overlay {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background: rgba(5, 25, 28, 0.5);
						z-index: 1;
					}

                    .bottom-overlay {
                        position: absolute;
                        width: 100%;
                        height: 54%;
                        left: 0;
                        bottom: 0;
                        z-index: 2;
                        background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);
                    }

					video,
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: absolute;
                        top: 0;
                        left: 0;
					}
				}

				.container {
					position: relative;
					height: 100%;
					display: flex;
					align-items: flex-end;
					justify-content: flex-start;

					.text-wrapper {
						z-index: 9;
						position: relative;
						bottom: 25px;
                        text-align: left;

                        h1, h2, h3, h4, h5, h6, p {
                            color: $luxury-white;
                        }

						&.small {
							width: 55%;

							@include breakpoint(md) {
								width: 60%
							}

							@include breakpoint(sm) {
								width: 75%;
							}

							@include breakpoint(xs) {
								width: 85%;
							}
						}
					}
				}
			}

            .parallax {
				position: relative;
				overflow: hidden;
				background-attachment: fixed;
				background-size: cover;
				background-position: center center;
				margin: 35px 0;
                background: linear-gradient(180deg, #05191C 0%, #05191C 100%);

				&.small {
					height: 435px;

					@include breakpoint(md) {
						height: 300px;
					}
				}

				&.medium {
					height: 445px;
				}

				&.large {
					height: 765px;
					@include breakpoint(lg) {
						height: 500px;
					}
				}

				&.no-margin-bottom {
					margin-bottom: 0;
				}

				&.no-margin-top {
					margin-top: 0;
				}

				.overlay {
					width: 100%;
					height: 100%;
					z-index: 1;
					position: absolute;
                    top: 0;
                    left: 0;
					background: linear-gradient(180deg, rgba(5, 25, 28, 0.8) 0%, rgba(5, 25, 28, 0.8) 100%);
				}
				.bg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 170%;
					z-index: 1;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
				}
				.container {
					height: 100%;
					width: 825px;
					max-width: 95%;
					position: relative;

					.text-wrapper {
						position: relative;
						display: flex;
						justify-content: center;
						flex-flow: column;
						height: 100%;
						z-index: 1;

						h2, h3, h4, h5, h6, p, li {
							color: white;
						}

						.button-wrapper {
							display: flex;
							justify-content: center;
						}
					}
				}
			}

			.single-column {
				position: relative;
				.container {
					p {
						text-align: left;
					}

					.image-wrapper {

						&.top-left {
							position: absolute;
							top: -190px;
							left: -130px;
							z-index: 1;
							width: 535px;
							height: 302px;
							object-fit: contain;
							@include breakpoint(lg) {
								top: -170px;
							}
						}

						&.bottom-right {
							position: absolute;
							bottom: -120px;
							right: -142px;
							z-index: 1;
							height: 404px;
							width: 508px;
							object-fit: contain;
							@include breakpoint(lg) {
								height: 225px;
							}
						}

						img {
							height: 100%;
							width: 100%;
							object-fit: contain;
						}
					}

					.text-wrapper {
						&.left {
							text-align: left;
						}

						ol {
							font-style: italic;
						}
					}
				}

				&.green {
					background: $rich-green-black;

					.container {
						margin-left: 10%;
						margin-right: auto;
						@include breakpoint(lg) {
							margin-left: auto;
						}
					}

					.text-wrapper {
						width: 990px;
						max-width: 74%;
						@include breakpoint(lg) {
							max-width: 100%;
						}
					}

					h1, h2, h3, h4, h5, h6, p {
						color: $luxury-white;
					}
				}

				&.extra-padding {
					padding: 245px 0px 150px 0;
					@include breakpoint(lg) {
						padding: 175px 0px 150px 0;
					}
					@include breakpoint(md) {
						padding: 125px 0px 150px 0;
					}
				}

				&.bottom-padding {
					padding-bottom: 90px;
				}
			}

            .two-column {
				position: relative;
				z-index: 2;
				&.reverse {
					.container {
						flex-flow: row-reverse;

						@include breakpoint(lg) {
							flex-flow: column;
						}

						.text-wrapper {

							@include breakpoint(lg) {
								width: 100%;
								padding-top: 30px;
								min-height: unset;
							}
						}
					}
				}

				&.extra-padding{
					padding-top: 150px;
				}

				&.object-fit {
					img {
						object-fit: contain !important;
						object-position: top left !important;
					}
				}

				.container {
					display: flex;
					justify-content: space-between;
					height: 100%;

					@include breakpoint(lg) {
						flex-flow: column;
					}

					.image-wrapper {
						width: 47%;
						height: auto;
						position: relative;

						@include breakpoint(lg) {
							width: 100%;
							height: 350px;
						}

						img {
							position: absolute;
							width: 100%;
							height: 100%;
							object-fit: cover;
							z-index: 0;
                            top: 0;
                            left: 0;
						}
					}

					.text-wrapper {
						position: relative;
						display: flex;
                        flex-flow: column;
						align-items: flex-start;
                        justify-content: center;
                        text-align: left;
						width: 47%;
						height: 100%;
						min-height: 350px;

						@include breakpoint(lg) {
							width: 100%;
							padding-top: 30px;
							min-height: unset;
						}

						.button-wrapper {
							display: flex;
							justify-content: center;

                            .btn {
                                background: $rich-green-black;
                                color: $luxury-white;
                            }
						}

						&.extra-small {
							min-height: 390px;
							@include breakpoint(lg) {
								min-height: unset;
							}
						}

                        &.small {
                            min-height: 505px;
							@include breakpoint(lg) {
								min-height: unset;
							}
                        }

                        &.medium {
                            min-height: 600px;
							@include breakpoint(lg) {
								min-height: unset;
							}
                        }

                        &.large {
                            min-height: 630px;
							@include breakpoint(lg) {
								min-height: unset;
							}
                        }

						&.center {
							text-align: center;
						}

						&.bold {
							h1, h2, h3, h4, h5, h6, p {
								font-weight: 700;
							}
						}
					}
				}

                &.full-width {
                    .container {
                        max-width: 100%;
                        margin: 0;
                        width: 100%;

                        .image-wrapper {
                            width: 41%;
							@include breakpoint(lg) {
								width: 100%;
								height: 325px;
							}
                            img {
                                object-position: top;
                            }
                        }

                        .text-wrapper {
                            width: 58%;
                            padding-right: 3.125%;
							@include breakpoint(lg) {
								width: 100%;
								padding: 0 2.5%;
							}
                        }
                    }
                }

				&.wide-text {
					.container {
                        max-width: 100%;
                        margin: 0;
                        width: 100%;

                        .image-wrapper {
                            width: 20%;
							@include breakpoint(lg) {
								width: 100%;
								height: 180px;
								position: absolute;
								bottom: -30px;
							}
                            img {
                                object-position: top;
								@include breakpoint(lg) {
									object-fit: contain;
									object-position: right;
								}
                            }
                        }

                        .text-wrapper {
                            width: 74%;
                            padding-left: 3.125%;

							p {
								font-style: italic;
								font-weight: 700;
								font-size: .72rem;
							}
                        }
                    }
				}
			}

			.two-column-table {
				div {
					display: flex;
					justify-content: space-between;

					.column-1, .column-2, .column-3 {
						padding: 34px 0;

						&.header {
							font-size: 1.6rem;
						}
					}

					.column-1, .column-2 {
						width: 48%;
						text-align: left;
					}

					.column-2 {
						display: flex;
						flex-flow: column;
					}

					h1, h2, h3, h4, h5, h6, p {
						color: $luxury-white;
						font-weight: 700;
					}
					&.black-green {
						background: $rich-green-black;
					}

					&.dark-green {
						background: $dark-green;
					}

					&.medium-green {
						background: rgba(0, 34, 21, 0.9);
					}
				}
			}

            .contact {
                .fade-background {
                    height: 172%;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);
                    z-index: 1;
                }

				.green-background {
					background: linear-gradient(180deg, rgba(5, 25, 28, 0.95) 0%, rgba(0, 34, 21, 0.95) 100%);
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					z-index: 1;
				}

				.small-circle, .large-circle {
					z-index: 0;
					position: absolute;
				}

				.small-circle {
					height: 300px;
					width: 300px;
					background: $highlight-green;
					box-shadow: 0px 0px 150px 150px $highlight-green;
					border-radius: 500px;
				}

				.large-circle {
					height: 980px;
					width: 980px;
					background: $highlight-green;
					opacity: 0.4;
					box-shadow: 0px 0px 150px 150px $highlight-green;
					border-radius: 500px;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;
				}

				.top-right {
					top: 0;
					right: 0;
				}

				.bottom-left {
					bottom: 0;
					left: 0;
				}

                .container {
					position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
					z-index: 2;
					@include breakpoint(lg) {
						flex-flow: column;
						gap: 50px;
					}

                    .left, .right  {
                        display: flex;
                        flex-flow: column;

                        label, h1, h2, h3, h4, h5, h6, p {
                            color: $luxury-white;
                            text-align: left;
                        }
                    }

                    .left {
						width: 43%;
						@include breakpoint(lg) {
							width: 100%;
						}
                        .contact-info {
                            margin-top: 25px;
                            background: linear-gradient(159.55deg, #FFFFFF 0%, #015F2A 156.71%);
                            box-shadow: 0px 10px 55px -25px rgba(255, 255, 255, 0.5);
                            border-radius: 45px;
                            padding: 50px 30px;
							@include breakpoint(xxl) {
								padding: 50px 20px;
							}

                            h1, h2, h3, h4, h5, h6, p, a {
                                color: $rich-green-black;
                            }

                            h4 {
                                text-align: center;
                                margin-bottom: 40px;
                            }

                            .content-wrapper {
                                display: flex;
                                gap: 35px;
                                align-items: center;
								@include breakpoint(xxl) {
									gap: 20px;
								}
								@include breakpoint(xl) {
									gap: 10px;
								}
                                h6 {
                                    margin-bottom: 0;
                                    font-size: 1.4rem;
									@include breakpoint(xxl) {
										font-size: 1.2rem;
									}
                                }

                                a {
                                    font-weight: inherit;
                                }
                            }

                            .address {
                                margin-bottom: 60px;
                            }
                        }
                    }

                    .right {
                        width: 52%;
						@include breakpoint(lg) {
							width: 100%;
						}
                    }
                }

				&.green-background {
					padding-top: 220px;
					padding-bottom: 90px;
					overflow: hidden;
					@include breakpoint(lg) {
						padding-top: 120px;
					}
				}
            }

			.contact-product {
                .container {

					label, h1, h2, h3, h4, h5, h6, p, span {
						color: $rich-green-black;
						&.modal {
							color: $highlight-green;
							cursor: pointer;
							&:hover {
								opacity: .8;
							}
						}	
					}

					.top-content {
						text-align: left;
					}

					#product-contact {
						width: 100%;
						text-align: left;

						button, .btn {
							background: $dark-green;
							color: $luxury-white;
							transition: background-color .3s ease-in-out;

							&:hover {
								background: $green;
								color: $luxury-white;
							}
						}

						input,
						textarea,
						select,
						.css-b62m3t-container,
						.css-13cymwt-control {
							border-bottom: 2px solid $rich-green-black;
							color: $rich-green-black;
						}

						.css-1jqq78o-placeholder,
						.css-1dimb5e-singleValue {
							color: $rich-green-black;
							font-family: $normsBold;
						}

						label {
							width: 100%;
						}

						.name, .physician, .physician-email {
							width: 48%;
						}

						.city, .state, .zip-code {
							width: 32%;
						}

						textarea {
							&::placeholder, option {
								color: $rich-green-black !important;
								font-size: 1.2rem !important;
							}
						}

						.css-1u9des2-indicatorSeparator {
							display: none;
							color: $rich-green-black;
						}

						.css-1jqq78o-placeholder {
							color: $rich-green-black;
							font-family: $normsBold ;
							font-weight: 700;
						}
					}
                }
            }

			.services {
				h3 {
					margin-bottom: 45px;
				}

				.grid {
					display: grid;
					grid-auto-rows: minmax(min-content, max-content);
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 45px;
					justify-items: center;
					align-items: center;
					max-width: 100%;
					height: auto;
					@include breakpoint(lg) {
						grid-template-columns: repeat(2, 1fr);
					}
					@include breakpoint(sm) {
						grid-template-columns: repeat(1, 1fr);
					}

					.grid-card {
						background: linear-gradient(154.18deg, #05191C 0%, #015F2A 100%);
						box-shadow: 0px 20px 40px -20px rgba(1, 95, 42, 0.5);
						border-radius: 45px;
						padding: 0 30px;
						height: 375px;
						display: flex;
						align-items: center;
						justify-content: center;

						p {
							color: $luxury-white;
							font-weight: 700;
							margin-bottom: 0;
						}
					}
				}
			}

			.faq {
				padding-top: 140px !important;
				padding-bottom: 70px;

				h2 {
					text-align: left;
					margin-bottom: 40px;
				}

				.faq-wrapper {
					text-align: left;
					cursor: pointer;
					h6 {
						color: $green;
						font-size: 1.2rem;
					}

					hr {
						background: #D9D9D9;
						border: none;
						height: 2px;
						margin:	0;
					}

					.question-wrapper {
						padding: 42px 0 25px 0;

						.icon-wrapper {
							display: flex;
							justify-content: space-between;
							align-items: flex-start;

							h5 {
								color: $green;
								font-size: 1.8rem;
								margin-block-start: 0;
								margin-block-end: 0;
							}
						}

						&:first-child {
							padding: 0 0 25px 0;
						}

						&:last-child {
							padding: 42px 0 0 0;
						}
					}
				}
			}

			.process {
				padding-top: 120px !important;
				background: linear-gradient(180deg, rgba(5, 25, 28, 0) 0%, #05191C 100%);


				h3 {
					margin-bottom: 55px;
				}

				.step {
					margin-top: 25px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					h6 {
						font-size: 1.4rem;
						color: white;
						@include breakpoint(sm) {
							font-size: 1rem;
						}
					}
				}

				.process-slider {
					display: flex;
					gap: 65px;
					overflow-x: scroll;
					height: 680px;
					@include breakpoint(lg) {
						height: auto;
						justify-content: stretch;
						padding-bottom: 80px;
					}
					@include breakpoint(sm) {
						gap: 15px;
					}

					.process-slider-card {
						background: linear-gradient(154.18deg, #05191C 0%, #015F2A 100%);
						box-shadow: 0px 20px 40px -20px rgba(1, 95, 42, 0.5);
						border-radius: 45px;
						padding: 30px 20px 10px;
						position: relative;
						height: 560px;
						width: 650px;
						display: flex;
						flex-flow: column;
						gap: 45px;
						flex: 0 0 auto;
						@include breakpoint(lg) {
							height: auto;
							max-width: 70%;
						}
						@include breakpoint(sm) {
							max-width: 90%;
							gap: 15px;
						}

						h3, h6, p {
							color: $luxury-white;
							text-align: left;
						}

						h3 {
							margin-bottom: 0;
						}
						.top {
							display: flex;
							justify-content: space-between;
							padding: 0 10px;

							.image-wrapper {
								position: relative;

								img {
									width: 125px;
									height: 125px;
									object-fit: contain;
									@include breakpoint(lg) {
										height: 75px;
										width: 75px;
									}
								}
							}

							.card-number {
								position: relative;
							}
						}
						.bottom{
							.card-info {
								position: relative;
								display: flex;
								flex-direction: column;
								justify-content: center;
							}
						}
					}

					&::-webkit-scrollbar{
						height: 30pxpx;
						width: 30pxpx;
						background: $rich-green-black;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 0;
					}

					/* Track */
					&::-webkit-scrollbar-track {
					  background: $green;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
					  background: $rich-green-black;
					  border-radius: 0;
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
					  background: $highlight-green;
					}

					&::-webkit-scrollbar-thumb:horizontal{
						background-color: $rich-green-black;
						border-radius: 0;
						width: 100px;
					}
				}
			}

            /************************************************
            Footer
            ************************************************/
            footer {
                background: $rich-green-black;
                padding: 43px 0;

				.logo {
					img {
						height: 75px;
						width: 75px;
					}
				}
                .container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
					@include breakpoint(md)  {
						flex-flow: column;
						gap: 30px;
					}
                }

                p, a {
                    font-weight: 700;
                    color: $luxury-white;
                    margin-bottom: 0;
                }
				br {
					display: none;
					@include breakpoint(xl) {
						display: block;
					}
				}
            }
        // }
    }
}